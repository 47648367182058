import * as React from "react"
import './CeraVe.css';
import Creative from "../../components/Creative"
import backgroundCeraVe from "./images/cerave_background.jpg"
import packshot from "./images/cerave_packshot.png"
import reflection from "./images/cerave_reflection.png"
import backImage from "./images/cerave_backImage.png"
import video from "./images/cerave_video.mp4"
import SimpleSidebar from "../../components/layout"

const CeraVe = () => (
  <SimpleSidebar>
    <Creative
      className="cerave"
      video={video}
      backImage={backImage}
      background={backgroundCeraVe}
      heading="CeraVe Cleansers"
      tags={["animation", "slider"]}  
      tagBackground="#002c4d"
      tagColor="#ffffff"
      headingColor="#002c4d"
    >    
      <img src={reflection} className="cerave_image cerave_reflection"/>
      <img src={packshot} className="cerave_image cerave_packshot"/>     
    </Creative>
  </SimpleSidebar>
)

export default CeraVe
